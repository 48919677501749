
<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="!blogId">
          Add Blog
        </h1>
        <h1 v-if="blogId">
          Edit Blog
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="primary"
          @click="blogs()"
        >
          Blogs
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!blogId"
          title="Blog Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="blogId"
          title="Blog Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
      <div v-if="showAlertSlugexit">
        <el-alert
          title="The slug has already been taken."
          type="error"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition" 
  >
    <el-row :gutter="20">
      <el-col :lg="18">
        <el-row :gutter="20">
          <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
          >
            <el-form-item
                label="Article Name"
                prop="name"
            >
              <el-input
                  v-model="data.name"
                  type="text"
                  autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
          >
            <el-form-item
                label="Slug"
                prop="slug"
            >
              <el-input
                  v-model="data.slug"
                  type="text"
                  autocomplete="off"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col
              :span="24"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
          >
            <el-form-item
                label="Description"
                prop="description"
            >
              <TinyMce
                  v-if="loadEditor"
                  v-bind:content="data.description"
                  @contentChanged="updateTextAreaValue"
              >
              </TinyMce>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
      <el-col :lg="6">
        <el-row :gutter="20">
          <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
          >
            <el-form-item
                label="Status"
                prop="status"
            >
              <select
                  v-model="data.status"
                  name="status"
                  class="selectstatus"
              >
                <!--Add options when value is id-->
                <option
                    v-for="item in selectstatus"
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                >
                  {{ item.label }}
                </option>
              </select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
          >
            <el-form-item
                label="Meta Tag Title"
                prop="meta_title"
            >
              <el-input
                  v-model="data.meta_title"
                  type="textarea"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
          >
            <el-form-item
                label="Meta Tag Description"
                prop="meta_description"
            >
              <el-input
                  v-model="data.meta_description"
                  type="textarea"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
        >
          <el-form-item
              label="Meta Tag Keywords"
              prop="meta_keyword"
          ><el-input
              v-model="data.meta_keyword"
              type="textarea"
          />
          </el-form-item>
        </el-col>
      </el-row>


      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <el-form-item
          label="Photo"
          prop="image"
        >
          <el-upload
            class="upload-demo"
            drag
            :action="imageUploadUrl"
            list-type="picture"
            :headers="imageUploadHeaders"
            name="file"
            :file-list="fileList"
            :limit="1"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleImageUploadSuccess"
            :on-error="handleImageUploadError"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              Drop file here or <em>click to upload</em><br>
              JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
            </div>
          </el-upload>
        </el-form-item>
      </el-col>
    </el-row> 
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item>
          <input
            id="blogid"
            v-model="blogId"
            name="blogid"
            type="hidden"
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            Submit
          </el-button>
          <el-button @click="resetForm('ruleForm')">
            Reset
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import BlogsService from "@/api/blogs.service";
import authHeader from '@/api/auth-header';
import TinyMce from "@/components/TinyMce";

export default {
    components: {
      TinyMce
    },
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Blog Name.'));
        } else {
          callback();
        }
      };
      var checkDescription = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Blog Description.'));
        } else {
          callback();
        }
      };
      var checkSlug = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Slug.'));
        } else {
          callback();
        }
      };
      var checkMetatitle = (rule, value, callback) => {        
          callback();
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        blogId: this.$route.params.blogId,
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
		selectstatus:[{
          value: 'Enabled',
          label: 'Enabled'
        }, {
          value: 'Disabled',
          label: 'Disabled'
        }],
        fileList: [],
        data: {
          name: null,
          image: null,
          imageNew: null,
          description: null,
          slug: null,
          meta_title: null,
          meta_description: null,
          meta_keyword: null,
          posted_on: null,
          status: "Enabled",
        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          name: [
            { validator: checkName, trigger: 'blur' }
          ],
          description: [
            { validator: checkDescription, trigger: 'blur' }
          ],
         
          slug: [
            { validator: checkSlug, trigger: 'blur' }
          ]
        }
      };
    },
	mounted() {
    this.setImageUploadUrl()
    if(this.blogId){
      this.getblog();
    }
  },
  computed: {
       loadEditor: function () {
        return !this.blogId || (this.blogId && this.data.description);
        },
  },
    methods: {
       updateTextAreaValue(value) {
        this.data.description = value;
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && !this.blogId) {
             return BlogsService.add(this.data).then(response => {
                // this.data = response.data
                this.showAlertAdded=true 
                return response
              });
          } else if (valid && this.blogId) {
            return BlogsService.update(this.blogId, this.data).then(response => {
              this.showAlertAdded=true;
              return response;

            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	blogs(){
		this.$router.push("/blog");
	},
	getblog(){
    return BlogsService.get(this.blogId).then(response => {
        this.data = response.data.data;
        this.fileList.push({name:'', url: response.data.data.image});
        return response;
      });
	},
      setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response, file, fileList) {
        if(response.code == 200) {
          this.data.imageNew = response.image_url;
        }
      },
      handleImageUploadError(err, file, fileList) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
        //TODO: Delete the file
      },
    }
  }
</script>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
.upload-demo{ display: flex;gap: 20px;width: 100%;max-width:100%;}
@media screen and (max-width: 767px) {
.upload-demo{ display: unset; gap: unset;width: unset;max-width:unset;}
}
</style>
